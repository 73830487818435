import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import theme from '../theme/theme'
import Layout from '../components/pageLayout/Layout'
import SEO from '../components/atoms/Seo'
import PageTitle from '../components/atoms/PageTitle'
import PortfolioImage from '../components/atoms/PortfolioImage'

const PortfolioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ theme: { pageMargins } }) => `
    padding: ${pageMargins.sm}px;
  `};
`

const Work = () => {
  const {
    allWordpressPost: { nodes: portolioProjects },
  } = useStaticQuery(graphql`
    {
      allWordpressPost(sort: { fields: date }) {
        nodes {
          slug
          wordpress_id
          acf {
            project_title
            project_main_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            project_order
          }
          link
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Work" />
        <PageTitle titleText="<h2>Some Projects</h2>" />
        <PortfolioContainer>
          {portolioProjects.map(({ acf, link, wordpress_id: wpId }) => (
            <PortfolioImage
              fluidImageUrl={
                acf.project_main_image.localFile.childImageSharp.fluid
              }
              link={link}
              title={acf.project_title}
              alt={acf.project_title}
              maxColumns={{ xs: 2, md: 3 }}
              key={wpId}
            />
          ))}
        </PortfolioContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default Work
