import styled from 'styled-components'
import PropTypes from 'prop-types'

const calculateColumnWidth = columnNumber => 100 / columnNumber

const PortolioImage = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${({ theme }) => `
    padding-top: ${theme?.pageMargins?.xs}px;
    padding-right: ${theme?.pageMargins?.xs}px;
    padding-bottom: ${theme?.pageMargins?.xs}px;
    padding-left: ${theme?.pageMargins?.xs}px;
  `}

  ${({ maxColumns, theme }) =>
    maxColumns &&
    maxColumns.xs &&
    `
    padding-top: ${theme?.pageMargins?.xs}px;
    padding-right: ${theme?.pageMargins?.xs}px;
    padding-bottom: ${theme?.pageMargins?.xs}px;
    padding-left: ${theme?.pageMargins?.xs}px;
    width: ${
      !maxColumns?.xs ? '100%' : `${calculateColumnWidth(maxColumns?.xs)}%`
    };
  `};

  ${({ maxColumns, theme }) =>
    maxColumns &&
    maxColumns.sm &&
    theme.media.sm`
    padding-top: ${theme?.pageMargins?.xs}px;
    padding-right: ${theme?.pageMargins?.xs}px;
    padding-bottom: ${theme?.pageMargins?.xs}px;
    padding-left: ${theme?.pageMargins?.xs}px;
    width: ${calculateColumnWidth(maxColumns?.sm)}%;
  `};

  ${({ maxColumns, theme }) =>
    maxColumns &&
    maxColumns.md &&
    theme.media.md`
    padding-top: ${theme?.pageMargins?.xs}px;
    padding-right: ${theme?.pageMargins?.xs}px;
    padding-bottom: ${theme?.pageMargins?.xs}px;
    padding-left: ${theme?.pageMargins?.xs}px;
    width: ${calculateColumnWidth(maxColumns?.md)}%};
  `};

  ${({ maxColumns, theme }) =>
    maxColumns &&
    maxColumns.lg &&
    theme.media.lg`
    width: ${calculateColumnWidth(maxColumns?.lg)}%};
  `};

  ${({ maxColumns, theme }) =>
    maxColumns &&
    maxColumns.xl &&
    theme.media.xl`
    width: ${calculateColumnWidth(maxColumns?.xl)}%};
  `};
`

PortolioImage.propTypes = {
  /** MaxColumns per breakpoint, all breakpoints are optional */
  maxColumns: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
}

PortolioImage.defaultProps = {
  maxColumns: null,
}

export default PortolioImage
