import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import PortfolioImageWrapper from './PortolioImageStyle'
import useHover from '../../../utils/useHover'

const Image = styled(Img)`
  height: 100%;
  position: absolute;
  z-index: 0;
`

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  max-height: 125px;

  ${({ theme: { media } }) => media.md`
    max-height: 300px;
    min-height: 300px;
  `}
`

const ImageOverlay = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-clip: content-box;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  ${({ theme: { colors, pageMargins } }) => `
    background-color: rgba(13, 12, 20, .4);
    color: ${colors?.lightBeige};
    padding: ${pageMargins?.xs}px;
  `}
`

const hoveredStyle = {
  opacity: 1,
}

const PortfolioImage = ({ fluidImageUrl, link, title, alt, maxColumns }) => {
  const [ref, hovered] = useHover()

  return (
    <PortfolioImageWrapper maxColumns={maxColumns} ref={ref}>
      <a href={`/work/project${link}`}>
        <ImageOverlay style={hovered ? hoveredStyle : {}}>{title}</ImageOverlay>
        <ImageContainer>
          <Image fluid={fluidImageUrl} alt={alt} />
        </ImageContainer>
      </a>
    </PortfolioImageWrapper>
  )
}

PortfolioImage.propTypes = {
  fluidImageUrl: PropTypes.object.isRequired,
  link: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  maxColumns: PropTypes.object,
}

PortfolioImage.defaultProps = {
  link: null,
  title: '',
  alt: '',
  maxColumns: null,
}

export default PortfolioImage
